import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <p
        style={{
          fontFamily: "RobotoMono",
          fontSize: "16px",
          marginLeft: "5%",
          color: "black",
        }}
      >
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        <br />
        <br />
        i'm doing best ok?
      </p>
    </div>
  );
};

export default NotFoundPage;
